$('a.privacidad').on('click', function(e) {

    var src = '/aviso-de-privacidad'
    var height = 500;
    var width = '100%';
    $("#legales_content").attr({'src':src,
        'height': height,
        'width': width});
    $(".modal.legales").modal('show');
});

$('a.aviso-medicos').on('click', function(e) {

    var src = '/aviso-de-privacidad-medicos'
    var height = 500;
    var width = '100%';
    $("#legales_content").attr({'src':src,
        'height': height,
        'width': width});
    $(".modal.legales").modal('show');
});

$('a.tyc').on('click', function(e) {

    var src = '/terminos-y-condiciones-popup';
    var height = 500;
    var width = '100%';
    $("#legales_content").attr({'src':src,
        'height': height,
        'width': width});
    $(".modal.legales").modal('show');
});

$('a.aspirantes').on('click', function(e) {

    var src = '/aviso-de-aspirantes';
    var height = 500;
    var width = '100%';
    $("#legales_content").attr({'src':src,
        'height': height,
        'width': width});
    $(".modal.legales").modal('show');
});

$('#form_val').on('submit', function(){

    if($('#tyc').length>0){
        if($('#tyc').is(':checked')==false){
            alert('Debes aceptar los términos y condiciones');
            return false;
        }
    }

    if($('#aap').is(':checked')==false){
        alert('Debes marcar que has leido el Aviso de Privacidad y Protección de datos personales');
        return false;
    }
});